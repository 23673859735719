<template>
  <div>
    <div v-if="rows <= 0">
      <p>Log is empty ...</p>
    </div>
    <div v-else>
      <b-table
        bordered
        small
        striped
        show-empty
        empty-text="Log is empty..."
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data">{{
          1 + data.index + (currentPage - 1) * perPage
        }}</template>
        <template v-slot:cell(actionDatetime)="data">{{
          data.value | datetimelong
        }}</template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auditHistories'],
  data() {
    return {
      item: {
        accountId: 1,
        action: 'CREATE',
        actionDatetime: '2020-02-14T13:51:51.534892',
        activityLogId: 22,
        data: 'id: 51 name: Bot J',
        itemId: 51,
        module: 'BOT',
        userName: 'sa@test.com',
      },
      fields: [
        {
          key: 'index',
          label: 'No.',
          sortable: false,
        },
        {
          key: 'actionDatetime',
          label: 'Datetime',
          sortable: true,
        },
        {
          key: 'userName',
          label: 'User',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: true,
        },
        {
          key: 'data',
          label: 'Details',
          sortable: false,
        },
      ],
      //items: [],
      perPage: 10,
      currentPage: 1,
      isBusy: false,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    items() {
      return this.auditHistories;
    },
  },
  methods: {},
};
</script>
