<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Bots</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3">
        <b-button size="sm" variant="primary" @click="showModal">
          <b-icon-plus style="font-size: 1.2em" />
          Create
        </b-button>
      </b-col>
    </b-row>
    <div ref="table-container">
      <small class="text-muted"
        >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
      <div v-if="isBusy" class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
      <b-table
        bordered
        small
        striped
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data"
          >{{ 1 + data.index + (currentPage - 1) * perPage }}
        </template>
        <template v-slot:cell(action)="data">
          <b-icon-pencil-square
            class="btn-inline-text"
            @click="goto(data.item.botId)"
            title="Bot Settings"
          ></b-icon-pencil-square>
          <b-icon-trash
            class="btn-inline-text ml-1"
            @click="deleteItem(data.item)"
            title="Delete"
          ></b-icon-trash>
          <b-icon-list-ul
            class="btn-inline-text ml-1"
            @click="showLogs(data.item.botId)"
            title="Show Logs"
          ></b-icon-list-ul>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
    </div>

    <b-modal
      v-model="modal"
      centered
      size="xl"
      ref="form-modal"
      title="Create Bot"
      id="main-modal"
    >
      <b-form ref="main-form">
        <b-row class="mb-2">
          <b-col cols="!2" lg="3">
            <label>Bot Name</label>
          </b-col>
          <b-col cols="!2" lg="9">
            <b-input
              v-focus
              class="form-control-sm"
              v-model.trim="formItem.botName"
              required
              @change="$sanitize('formItem.botName')"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="!2" lg="3">
            <label>Bot Language</label>
          </b-col>
          <b-col cols="!2" lg="9">
            <b-form-select
              size="sm"
              v-model="formItem.botLanguage"
              :options="languages"
              value-field="languageId"
              text-field="languageName"
            ></b-form-select>
          </b-col>
        </b-row>
        <!--        <b-row class="mb-2">-->
        <!--          <b-col cols="!2" lg="3">-->
        <!--            <label>Bot Type</label>-->
        <!--          </b-col>-->
        <!--          <b-col cols="!2" lg="9">-->
        <!--            <b-form-select-->
        <!--              size="sm"-->
        <!--              v-model="formItem.botType"-->
        <!--              :options="botTypes"-->
        <!--            ></b-form-select>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <section v-if="formItem.botType === 2">-->
        <!--          <hr />-->
        <!--          <b-row>-->
        <!--            <b-col>-->
        <!--              <bot-skype-configs v-model="botSkypeConfig" />-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </section>-->
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="modal = false">Cancel</b-button>
        <b-button variant="primary" @click="promptConfirm">Submit</b-button>
      </template>
    </b-modal>

    <b-modal size="lg" v-model="auditModal" centered hide-footer>
      <audit-history :audit-histories="auditHistories"></audit-history>
    </b-modal>
  </div>
</template>

<script>
/*global _*/
import httpclient from '@/mixins/HttpHandler';
import AuditHistory from '@/components/Audit/AuditHistory';
// import BotSkypeConfigs from '@/components/Bots/BotSkypeConfigs.vue';

export default {
  mixins: [httpclient],
  components: { AuditHistory },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'main-modal') {
        this.$refs['main-form'].querySelector('input').focus();
      }
    });
    this.getList();
  },
  data() {
    return {
      modal: false,
      auditModal: false,
      languages: [
        { languageId: 'English', languageName: 'English' },
        { languageId: 'Chinese', languageName: 'Chinese' },
        { languageId: 'Vietnamese', languageName: 'Vietnamese' },
        { languageId: 'Thai', languageName: 'Thai' },
      ],
      // botTypes: [
      //   { value: 0, text: 'Old Bot' },
      //   { value: 1, text: 'Mario' },
      // ],
      path: 'bots',
      formItem: {
        botId: 0,
        botName: '',
        botLanguage: 'English',
        botType: 1,
      },
      botSkypeConfig: {
        skypeUserName: '',
        skypePassword: '',
        skypeUserId: '',
        skypeDisplayName: '',
        csSkypeUserName: '',
        brand: '',
        messageEmptyRequest: '',
        messageFileDownloaded: '',
      },
      auditHistories: [],
      fields: [
        {
          key: 'index',
          label: 'No.',
          sortable: false,
        },
        {
          key: 'botName',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'botLanguage',
          label: 'Language',
          sortable: true,
        },
        {
          key: 'intentCount',
          label: 'Intent Count',
          sortable: true,
        },
        {
          key: 'botId',
          label: 'Bot ID',
          sortable: true,
        },
        {
          key: 'accountId',
          label: 'Account ID',
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          sortable: false,
        },
      ],
      items: [],
      perPage: 100,
      currentPage: 1,
      isBusy: false,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    showModal() {
      this.formItem = _defaultFormItem();
      this.botSkypeConfig = _defaultBotSkypeConfig();
      this.formItem.options = [''];
      this.modal = true;
    },
    getList() {
      const vm = this;
      this.httpclient.get('api/bots').then(function (resp) {
        if (resp.data) {
          vm.items = resp.data;
          vm.modal = false;
        }
      });
    },
    deleteItem(data) {
      const vm = this;
      var confirmMessage =
        'Delete the bot? This will also delete all data related to this bot. Please make sure that this is the bot to delete. This action is not reversible.';
      var id = data.botId;

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.httpclient.delete('api/bots/' + id).then(function (resp) {
              if (resp.data) {
                var index = _.findIndex(vm.items, { botId: id });
                vm.items.splice(index, 1);
                vm.modal = false;
                vm.$bvModal.msgBoxOk('Bot had been deleted successfully', {
                  okVariant: 'success',
                  centered: true,
                });
              }
            });
          }
        });
    },
    promptConfirm() {
      const vm = this;
      var mainForm = vm.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      vm.modal = false;

      var confirmMessage = 'Create the bot?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.addItem();
          } else {
            vm.modal = true;
          }
        });
    },
    addItem() {
      const vm = this;
      const formItem = { ...this.formItem };
      formItem.botSkypeConfig = { ...this.botSkypeConfig };
      vm.httpclient.post('api/bots', formItem).then(function (resp) {
        if (resp.data) {
          vm.getList();
          vm.modal = false;
          vm.$bvModal.msgBoxOk('Bot had been created successfully', {
            okVariant: 'success',
            centered: true,
          });
        }
      });
    },
    showLogs(botId) {
      const vm = this;
      vm.httpclient.get('api/bots/' + botId + '/logs').then(function (resp) {
        if (resp.data) {
          vm.auditHistories = resp.data;
          vm.auditModal = true;
        }
      });
    },
    goto(botId) {
      this.$store.commit('updateSelectedBotId', botId);
      this.$router.push({ name: 'bot-settings' });
    },
  },
};

const _defaultBotSkypeConfig = () => {
  return {
    skypeUserName: '',
    skypePassword: '',
    skypeUserId: '',
    skypeDisplayName: '',
    csSkypeUserName: '',
    brand: '',
    messageEmptyRequest: '',
    messageFileDownloaded: '',
  };
};

const _defaultFormItem = () => {
  return {
    botId: 0,
    botName: '',
    botLanguage: 'English',
    botType: 1,
  };
};
</script>

<style lang="scss" scoped>
@import 'src/variables.scss';

.btn-inline-text {
  &:hover {
    @extend %clickable;
  }
}
</style>
